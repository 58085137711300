import { useEffect, useState } from 'react';
import { commitMutation, graphql, useRelayEnvironment } from 'react-relay';
import { Markdown, lang, useTranslation } from '@/helpers/translate';
import { useZusModalStore, ModalWrapper } from '@/helpers/modal.js';
import { useZusUserConnectedStore } from '@/helpers/user-auth.js';
import { getPathByLang } from '@/helpers/router';
import { useButton } from '@/core/button';

const approvalMutation = graphql`
  mutation cgvapprovalMutation {
    user {
      response: cgvApproval {
        code
        message
      }
    }
  }
`;

const onClickApprovalMutation = (environment, callback) => {
  commitMutation(
    environment,
    {
      mutation: approvalMutation,
      variables: {},
      onCompleted: (res) => {
        if (res?.user?.response?.code !== 200) return callback(false);
        return callback(true);
      },
      onError: (err) => {
        console.error(err);
        return callback(false);
      },
    },
  );
};

export default function CgvApprovalModal() {
  const { t } = useTranslation();
  const langCurrent = lang();
  const userConnected = useZusUserConnectedStore((state) => state.userData);
  const environment = useRelayEnvironment();
  const { setLoading, ButtonComponent } = useButton();
  const [open, setOpen] = useState(false);

  const renderContent = () => <div className="w-screen max-w-md px-12 py-10 bg-white h-dvh lg:h-full lg:w-full lg:rounded-xl">
      <div className="flex flex-col mt-3 text-center gap-y-2">
        <h4 className="text-xl">Mise à jour des CGV</h4>
        Dans le cadre de nos évolutions, nous mettons à jour nos Conditions générales d'utilisation.<br />
        Nous vous invitons à les lire et à les accepter ✍️, pour continuer à utiliser OfficeRiders.<br />
        <a className="text-dark hover:text-black" href={getPathByLang('terms', langCurrent)} target="_blank" rel="noreferrer">Lire les nouvelles CGV</a>
        <ButtonComponent
          className="px-4 py-2 text-white bg-blue"
          onClick={() => {
            setLoading(true);
            onClickApprovalMutation(environment, (res) => {
              if (res === true) {
                const { setModal3 } = useZusModalStore.getState();
                setModal3(null);
              } else {
                setLoading(false);
              }
            });
          }}
        >J'accepte et continue
        </ButtonComponent>
        <br />
        Merci de faire partie de notre communauté grandissante ! 🫶
      </div>
                              </div>;

  useEffect(() => {
    if (userConnected?.isCGVApproval && !open) {
      const { setModal3 } = useZusModalStore.getState();
      setModal3(<ModalWrapper noClose>
        {renderContent()}
                </ModalWrapper>);
      setOpen(true);
    }
  }, [userConnected]);

  return null;
}
