import PropTypes from 'prop-types';
import { useState } from 'react';
import Loading from '@/core/loading.js';

function Button({ children, onClick, ...props }) {
  const [loading, setLoading] = useState(false);

  async function buttonOnClick(...args) {
    if (loading) return true;
    setLoading(true);
    if (!onClick) {
      return setLoading(false);
    }
    await onClick.apply(args);
    return setLoading(false);
  }
  let addProps = {
    onClick: buttonOnClick,
  };
  if (loading) {
    addProps = {
      disabled: true,
    };
  }
  return (
    <button {...props} {...addProps}>
      {loading ? <Loading /> : children}
    </button>
  );
}

Button.propTypes = {
  // func must return a Promise
  onClick: PropTypes.func.isRequired,
};

const useButton = () => {
  const [loading, setLoading] = useState(false);

  function ButtonComponent({ children, onClick, ...props }) {
    if (loading) {
 return (
      <div className="flex items-center justify-center px-6 py-3 text-white rounded-lg bg-dark-light">
        <Loading />
      </div>);
}
    return <button {...props} {...(onClick ? { onClick } : {})}>{children}</button>;
  }

  return { setLoading, ButtonComponent };
};

export { useButton };
export default Button;
